<template>
  <section id="welcome" class="mt-15 mt-md-0 mt-lg-0 mt-xl-0">
    <transition enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut" mode="out-in" appear>
      <v-row class="h-full">
        <v-col cols="12" md="6" class="personal-text order-1 order-md-0">
          <base-text class="text-uppercase">{{
            personalInfo.vocation
          }}</base-text>
          <base-heading :color="'primary'">
            {{ personalInfo.salut }}
          </base-heading>
          <base-text v-html="welcome" class="text-body-2 text-md-body-1">
          </base-text>
        </v-col>
        <v-col cols="12" md="6" class="personal-image order-0 order-md-1">
          <v-avatar v-if="isMobile" color="primary" size="200"><v-img :src="require('@/assets/me.jpg')" /></v-avatar>
          <v-avatar v-else color="primary" size="400"><v-img :src="require('@/assets/me.jpg')" /></v-avatar>
        </v-col>
      </v-row>
    </transition>
    <div class="text-center pa-4">
      <p class="text-uppercase">Scroll down</p>
      <div class="bounce">
        <v-icon color="primary" size="30">mdi-chevron-down</v-icon>
      </div>
    </div>
  </section>
</template>
<script>
import mdfile from "@/assets/data/content/welcome.md";
import personalInfo from "@/assets/data/personalInfo.json";
import isMobile from "../plugins/isMobile";
import * as sd from "showdown";
export default {
  name: "WELCOME",
  data() {
    return {
      welcome: "",
      personalInfo: personalInfo,
    };
  },
  computed: {
    isMobile() {
      return isMobile();
    },
  },
  mounted() {
    const converter = new sd.Converter();
    this.welcome = converter.makeHtml(mdfile);
  },
};
</script>
<style scoped>
.personal-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.personal-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bounce {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  -moz-animation: bounce 3s infinite;
  -o-animation: bounce 3s infinite;
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(-10px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  50% {
    -moz-transform: translateY(-20px);
  }

  50% {
    -moz-transform: translateY(-10px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  50% {
    -o-transform: translateY(-20px);
  }

  50% {
    -o-transform: translateY(-10px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }
}</style>
